import { createMuiTheme } from '@material-ui/core/styles'
import { heading } from '../../utils/utils'

const mainFont = 'ProximaNovaRegular'
const titleFont = 'ProximaNovaBold'

const white = '#fff'
const black = '#000'
//const primaryMain = '#00233A' origine => remplacée par celle en dessous
const primaryMain = '#1e1e1e'
//const primaryLight = '#E5E9EB' origine => remplacée par celle en dessous
const primaryLight = '#f7f7f7' //l'équivelent de gray juste en dessous
const secondaryMain = '#1e1e1e' //pas de variable encore ici
//const greyLight = '#F7F7F9'

//Paul variables supplémentaires
const gray = '#f7f7f7'
const blackPrimary = '#1e1e1e'
const red = '#b3282d'

// const imp = '!important'

// const f11 = `11px`
const f12 = `12px`
const f13 = `13px`
const f14 = `14px`
const f15 = `15px`
const f16 = `16px`
const f18 = `18px`

// const f20 = `20px`
// const f22 = `22px`
const f24 = `24px`
// const f30 = `30px`
const f38 = `38px !important`

const body = {
  fontSize: f16, //de base f14, passée en f16
  lineHeight: 1.6,
}
const body2 = {
  fontSize: f16, //de base f14, passée en f16
  lineHeight: 1.6, //de base 1.67, passée en f16
}

const theme = createMuiTheme({
  themeFont: {
    //WARNING FONT NAME WITH SPACE SHOULD HAVE A +. NEXT JS TRIGGER BAD URL FOR GOOGLE FONT
    main: mainFont,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: `${mainFont}, sans-serif`,
    h1: {
      lineHeight: 1.15,
      ...heading(f38, f38, 700, 700, titleFont, titleFont, false),
    },
    h2: {
      lineHeight: 1.15,
      ...heading(f24, f24, 600, 700, mainFont, mainFont, false),
    },
    h3: {
      lineHeight: 1.4,
      ...heading(f18, f18, 700, 700, mainFont, mainFont, false),
    },
    h4: {
      lineHeight: 1.55,
      ...heading(f16, f16, 700, 700, mainFont, mainFont, false),
    },
    h5: {
      lineHeight: 1.25,
      ...heading(f14, f14, 600, 700, mainFont, mainFont, false),
    },
    h6: {
      lineHeight: 1.25,
      ...heading(f12, f12, 600, 700, mainFont, mainFont, false),
    },
    body1: {
      ...body,
      //color: `${gray} !important`,
    },
    body2: {
      ...body2,
    },
    caption: {
      fontSize: f14, //f12 à l'origine
      lineHeight: 1.17,
      fontWeight: 400,
    },
    button: {
      fontSize: f15,
      lineHeight: 1.67,
      textTransform: 'inherit',
    },
  },
  palette: {
    common: {
      black: primaryMain,
      white: white,
    },
    background: {
      default: white,
      paper: white,
    },
    primary: {
      main: primaryMain,
    },
    secondary: {
      main: secondaryMain,
    },
    success: {
      main: '#47E169',
    },
    error: {
      main: '#FA3232',
    },
    warning: {
      main: '#FF8333',
    },
    divider: gray,
    primaryTransparent: {
      main: primaryMain,
      light: primaryLight,
    },
    secondaryTransparent: {
      main: primaryMain,
      light: primaryLight,
    },
    ternary: {
      main: '#0082C3',
      light: '#F1FAFF',
    },
    accent: {
      main: '#FFEA28',
    },
  },
  sizes: {
    headerHeight: 60,
    headerHeightTransparent: 90,
    logoWidth: 110,
    logoHeight: 30,
    footerBG: 168,
    basketWidth: 340,
    basketWidthLG: 440,
  },
  extraColors: {
    grey: '#D5D2D9',
    greyLight: '#F5F3F8',
    greyMedium: '#C0BCC6',
    greyText: '#6F677B',
  },
  shadows: [
    'none',
    '0px 0px 15px rgba(46, 46, 46, 0.1)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '12px 45px',
        borderRadius: 4,
      },
      sizeSmall: {
        padding: '4px 16px',
        lineHeight: 1.6,
      },
      sizeLarge: {
        padding: '12px 45px',
      },
      contained: {
        boxShadow: 'none',
        color: `${white} !important`,
        backgroundColor: `${blackPrimary} !important`,
        fontSize: '13px !important',
        '&:hover, &:focus, &:active, &.selected': {
          boxShadow: 'none',
          backgroundColor: `${red} !important`,
        },
      },
      outlined: {
        padding: '12px 45px',
      },
      outlinedSizeLarge: {
        padding: '12px 45px',
      },
      outlinedSizeSmall: {
        padding: '4px 16px',
        lineHeight: 1.6,
      },
    },
    MuiPaper: {
      root: {
        boxShadow: '0px 0px 15px rgba(46, 46, 46, 0.1)',
      },
      rounded: {
        borderRadius: 4,
      },
    },
    MuiSelect: {
      root: {
        color: primaryMain,
      },
      selectMenu: {
        '&:focus': {
          background: white,
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: 0,
        fontSize: 15,
        fontWeight: 700,
        color: black,
        backgroundColor: 'transparent',
        height: 25,
        padding: 0,
      },
      label: {
        padding: 0,
      },
      sizeSmall: {
        height: 22,
        fontSize: 12,
        fontWeight: 400,
      },
      labelSmall: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      colorPrimary: {
        color: primaryMain,
        backgroundColor: 'transparent',
      },
      colorSecondary: {
        color: secondaryMain,
        backgroundColor: 'transparent',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&.Mui-checked': {
          color: `${primaryMain} !important`,
        },
      },
    },
    //Paul jeudi 25 janvier 2024
    MuiCssBaseline: {
      '@global': {
        '.footer': {
          backgroundColor: `${blackPrimary} !important`,
          '&__top': {
            backgroundColor: `${gray} !important`,
            '&__title': {
              color: `${blackPrimary} !important`,
            },
          },
          '&__logo': {
            width: '150px !important',
            height: 'auto !important',
          },
          '&__bottom': {
            backgroundColor: 'unset !important',
          },
          '&__copyright': {
            backgroundColor: 'unset !important',
          },
          '&__menu': {},
          '&__bottomRow2': {},
        },

        '.faq': {
          '&__question': {
            fontFamily: `${mainFont}, sans-serif`,
            fontSize: '16px !important',
            '&[aria-expanded="false"]': {
              backgroundColor: `${gray} !important`,
              '&:hover, &:focus, &:active, &.selected': {
                backgroundColor: `${gray} !important`,
              },
            },
            '&[aria-expanded="true"]': {
              backgroundColor: `${blackPrimary} !important`,
              '&:hover, &:focus, &:active, &.selected': {
                backgroundColor: `${blackPrimary} !important`,
              },
            },
          },
          '&__btn': {},
          '&__answer': {
            backgroundColor: `${white} !important`,
            fontFamily: `ProximaNovaLight, sans-serif`,
          },
          '&-page': {},
          '&-wrap': {},
        },

        '.section-hiw': {
          '&__title': {},
          '&__spacing': {
            marginBottom: '24px !important',
            gap: '10px !important',
            '&>div': {
              backgroundColor: `${white} !important`,

              '@media (min-width: 768px)': {
                //width: 'calc(33.33% - 6.66px)',
                flex: '1 !important',
              },
            },

            '& .hiw': {
              '&__icon': {
                '& img': {
                  '@media (min-width: 768px)': {
                    width: 'auto !important',
                    height: '100px !important',
                  },
                },
              },
              '&__title': {
                fontFamily: `ProximaNovaBold, sans-serif`,
                textTransform: 'uppercase',
                fontSize: '30px !important',

                '@media (min-width: 1024px)': {
                  fontSize: '30px !important',
                },
              },
              '&__head': {
                '@media (min-width: 768px)': {
                  paddingTop: '20px !important',
                },
              },
            },
          },
        },

        '.productCard': {
          '&__title': {
            fontFamily: 'ProximaNovaBold, sans-serif',
          },
        },

        '.banner': {
          '& p': {
            color: `${white} !important`,
          },
        },

        '.menu': {
          '&__item': {
            fontFamily: `ProximaNovaBold, sans-serif`,

            '@media (min-width: 1024px)': {
              fontSize: '14px !important',
            },
          },
        },

        '.cart': {
          '&-badge': {
            backgroundColor: `${red} !important`,
          },
        },
        '.basket': {
          '& img': {
            width: '30px !important',
          },
          '&__time,&__time span': {
            fontSize: '13px !important',
          },
          '&__title': {
            fontSize: '14px !important',
          },
          '&__variant': {
            color: `${blackPrimary} !important`,
          },
        },

        '.tagline': {
          color: `${white} !important`,
          fontSize: '18px !important',
          '& p': {
            color: `${white} !important`,
          },
        },

        '.react-datepicker': {
          '&__navigation': {
            backgroundColor: `${blackPrimary} !important`,
            color: `${white} !important`,
          },
          '&__day': {
            '&--selected': {
              backgroundColor: `${blackPrimary} !important`,
              color: `${white} !important`,
            },
            '&--in-range': {
              backgroundColor: `${blackPrimary} !important`,
              color: `${white} !important`,
            },
            '&--today': {
              backgroundColor: `${red} !important`,
              color: `${white} !important`,
            },
            '&:hover': {
              backgroundColor: `${red} !important`,
              color: `${white} !important`,
            },
          },
        },

        '& .datePicker__text': {
          color: `${blackPrimary} !important`,
        },

        '.product': {
          '&__prev': {
            color: `${blackPrimary} !important`,
            borderColor: `${blackPrimary} !important`,
            '&:hover': {
              backgroundColor: `${white} !important`,
            },
          },
          '&__bottom': {
            '@media (max-width: 768px)': {
              flexDirection: 'column !important',
              alignItems: 'flex-start !important',
            },
            '&-link': {
              fontSize: '0.875rem !important',
            },
            '&>div': {
              '@media (max-width: 768px)': {
                //display: 'none !important',
                rotate: '90deg !important',
              },
            },
          },
        },
        '.pack': {
          '&__prev': {
            color: `${blackPrimary} !important`,
            borderColor: `${blackPrimary} !important`,
            '&:hover': {
              backgroundColor: `${white} !important`,
            },
          },
        },
        '.sizes': {
          '&__btn': {
            '&:hover': {
              backgroundColor: `${red} !important`,
            },
            '&.selected': {
              backgroundColor: `${red} !important`,
            },
          },
          '&__chip': {
            backgroundColor: `${blackPrimary} !important`,
            '&:hover': {
              backgroundColor: `${blackPrimary} !important`,
            },
            '&.selected': {
              backgroundColor: `${blackPrimary} !important`,
            },
          },
        },

        '.price': {
          '&__amount': {
            color: `${blackPrimary} !important`,
          },
          '&__duration': {
            color: `${blackPrimary} !important`,
          },
        },

        '.section-discover': {
          '&__subtitle': {
            textAlign: 'center !important',
          },
        },

        '.discover': {
          '&__shadow': {
            backgroundColor: `${gray} !important`,
            display: 'flex',
            flexDirection: 'column-reverse',
          },
          '&-product': {
            '&__content': {
              padding: '16px 16px 8px !important',
            },
          },
        },

        '.deliveryDate': {
          color: `${blackPrimary} !important`,
        },

        '.funnel': {
          '&__nav': {
            '&--active': {
              color: `${blackPrimary} !important`,
              borderColor: `${blackPrimary} !important`,
            },
          },
          '&__box': {
            '& #customCard-container label, & #customCard-container label span':
              {
                fontSize: '14px !important',
              },

            '& .MuiFormControlLabel-root p': {
              fontSize: '18px !important',
            },
          },
        },

        '.MuiTooltip': {
          '&-tooltip': {
            backgroundColor: `${black} !important`,
          },
          '&-arrow': {
            color: `${blackPrimary} !important`,
            backgroundColor: 'none !important',
          },
        },

        '.header': {
          '&__layout': {
            '@media (min-width: 1024px)': {
              gap: '16px !important',
            },
          },
          '&__col': {
            '@media (min-width: 1024px)': {
              //width: 'auto !important',//au final pas appliqué car on veut que le menu soit centré
            },
          },
          '&__cta': {
            '@media (min-width: 1024px)': {
              fontSize: '14px !important',
            },
          },
        },

        '.section-infos': {
          '&__content h2': {
            fontSize: '25px !important',
            '@media (min-width: 1024px)': {
              fontSize: '28px !important',
            },
          },
        },

        '.shop-filters': {
          fontSize: '16px !important',
        },

        '.daterange': {
          '&__submit': {
            fontSize: '16px !important',
          },
        },

        '.shipping-infos': {
          '&__start>p,&__end>p': {
            fontSize: '14px !important',
          },
          '&__start>span,&__end>span': {
            fontSize: '16px !important',
          },
        },

        '.page-content': {
          '&>section:last-of-type': {
            paddingBottom: '48px !important',
          },
        },

        '.shop__planner': {
          '@media (max-width: 480px)': {
            marginTop: '-72px !important',
          },
        },

        '.hero--shop': {
          '& .hero__title__container': {
            '@media (max-width: 480px)': {
              minHeight: '200px !important',
            },
          },
        },
        '.hero__toptitle': {
          textAlign: 'center',
        },
        '.contact-page': {
          '& label': {
            fontSize: '14px !important',
            '& p': {
              fontSize: '14px !important',
            },
          },
        },
        '.recapOrder__title': {
          '& h3': {
            color: `${blackPrimary} !important`,
          },
        },
      },
    },
  },
})

export default theme
